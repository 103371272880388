import { mdiCircle, mdiHomeOutline, mdiSunglasses, mdiTableLargePlus, mdiTrayFull, mdiUpdate } from '@mdi/js'
import { APP_VERSION } from '../../version'

export default [
  {
    title: 'Gösterge Paneli',
    icon: mdiHomeOutline,
    to: 'dashboard',
  },
  {
    subheader: 'Sipariş',
  },
  {
    title: 'Sipariş Oluşturma',
    icon: mdiTableLargePlus,
    to: 'order-create',
  },
  {
    title: 'Stok Siparişler',
    icon: mdiSunglasses,
    to: 'order-stok',
  },
  {
    title: 'Lens Siparişler',
    icon: mdiCircle,
    to: 'order-lens',
  },

  {
    subheader: 'Rapor',
  },
  {
    title: 'Ekstre',
    icon: mdiTrayFull,
    to: 'extarct-list',
  },
  {
    title: `Versiyon: ${APP_VERSION}`,
    icon: mdiUpdate, // Aynı ikonu kullanabiliriz veya farklı bir ikon ekleyebilirsiniz.
    disabled: true, // Bu öğe tıklanabilir olmasın, sadece bilgi olsun
  },
]
