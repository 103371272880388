<template>
  <div>
    <!-- Bu div, tüm bileşeni saracak -->
    <component :is="resolveLayoutVariant" :class="`skin-variant--${appSkinVariant}`">
      <transition :name="appRouteTransition" mode="out-in" appear>
        <router-view></router-view>
      </transition>
    </component>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
import { useLayout } from '@core/layouts/composable/useLayout'
import { useRouter } from '@core/utils'

// Layouts
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'
import LayoutContentHorizontalNav from '@/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue'
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import useDynamicVh from '@core/utils/useDynamicVh'
import Swal from 'sweetalert2'

// Dynamic vh

import { APP_VERSION } from './version'

export default {
  components: {
    LayoutContentVerticalNav,
    LayoutContentHorizontalNav,
    LayoutBlank,
  },
  setup() {
    const version_no = APP_VERSION
    const { route } = useRouter()
    const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig()

    const { handleBreakpointLayoutSwitch } = useLayout()
    handleBreakpointLayoutSwitch()

    const resolveLayoutVariant = computed(() => {
      if (route.value.meta.layout === 'blank') return 'layout-blank'
      if (route.value.meta.layout === 'content') return `layout-content-${appContentLayoutNav.value}-nav`

      return null
    })

    useDynamicVh()

    return {
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
      version_no,
    }
  },
  mounted() {
    // this.checkForUpdates()
    // visibilitychange olayı ile sayfa aktif olduğunda kontrol et
    document.addEventListener('visibilitychange', this.handleVisibilityChange)

    // Sayfa yüklendiğinde de versiyon kontrolü yap
    this.checkForUpdateOnLoad()
  },
  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange)
  },
  methods: {
    handleVisibilityChange() {
      if (document.visibilityState === 'visible') {
        /* Swal.fire({
          title: 'Uygulama Aktif Oldu!',
          text: 'Uygulama tekrar aktif oldu ve güncelleme kontrol ediliyor...',
          icon: 'info',
        }) */

        this.checkForUpdate() // ✅ Uygulama tekrar aktif olunca kontrol et
      }
    },

    checkForUpdates_OLD() {
      const currentVersion = localStorage.getItem('app_version')

      // Versiyon değişmişse güncelle
      if (currentVersion !== APP_VERSION) {
        localStorage.setItem('app_version', APP_VERSION)
        console.log('Yeni sürüm algılandı. Sayfa yenileniyor...')
        window.location.reload(true) // Zorunlu yenileme
      }
    },
    checkForUpdate() {
      const storedVersion = localStorage.getItem('appVersion')

      /* Swal.fire({
        title: 'Versiyon Kontrolü',
        text: `Stored Version: ${storedVersion}`,
        icon: 'info',
      }) */

      // Eğer localStorage'da versiyon bilgisi yoksa, ilk defa kaydediyoruz
      if (!storedVersion) {
        localStorage.setItem('appVersion', APP_VERSION)

        /*   Swal.fire({
          title: 'Versiyon Kaydedildi',
          text: `Versiyon bilgisi kaydedildi: ${APP_VERSION}`,
          icon: 'info',
        }) */
      }

      const currentVersion = storedVersion || APP_VERSION

      // Eğer versiyonlar farklıysa güncelleme yapılacak
      if (currentVersion !== APP_VERSION) {
        localStorage.setItem('appVersion', APP_VERSION) // Yeni versiyonu kaydediyoruz
        Swal.fire({
          title: 'Güncelleme Mevcut!',
          text: 'Yeni bir güncelleme var. Sayfayı yenilemek ister misiniz?',
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Evet, Yenile',
          cancelButtonText: 'Hayır',
        }).then(result => {
          if (result.isConfirmed) {
            window.location.reload() // Sayfayı yenile
          }
        })
      }
    },

    // Sayfa ilk yüklendiğinde güncellemeyi kontrol et
    checkForUpdateOnLoad() {
      setTimeout(() => {
        /*  Swal.fire({
          title: 'Sayfa Yükleniyor...',
          text: 'Sayfa yüklendi ve güncelleme kontrol ediliyor...',
          icon: 'info',
        }) */

        this.checkForUpdate()
      }, 500) // 500ms gecikme ile kontrol et
    },
  },
}
</script>
